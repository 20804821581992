import { get } from 'lodash';
import React from 'react';

import { SENTRY_VIEWER_SCRIPT_DSN } from '../constants/sentry-viewer-script-dsn';
import { getEnvironmentTag, normalizeError } from '../services/create-monitoring';

export function withSentryErrorBoundary(ComponentToWrap, origin, ErrorStateComponent) {
  return class SentryErrorBoundary extends React.Component {
    state = {
      error: null,
      errorId: null,
    };

    componentDidCatch(error) {
      const lazySentry = new this.props.host.LazySentry({
        dsn: SENTRY_VIEWER_SCRIPT_DSN,
        environment: getEnvironmentTag({
          origin,
          isSSR: this.props.isSSR,
          isSeo: get(this.props, 'state.basicParams.isSeo'),
        }),
        beforeSend: (e) => {
          this.setState({ error, errorId: e.event_id });
          return e;
        },
      });

      lazySentry.captureException(normalizeError(error));
    }

    render() {
      return this.state.error && ErrorStateComponent ? (
        <ErrorStateComponent error={this.state.error} errorId={this.state.errorId} {...this.props} />
      ) : (
        <ComponentToWrap {...this.props} />
      );
    }
  };
}
