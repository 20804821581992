import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { setWebpackPublicPath } from '../services/set-webpack-public-path';

export const withWebpackPublicPath = (WrappedComponent) => {
  class WithCustomStyles extends React.Component {
    static displayName = `withWebpackPublicPath(${getDisplayName(WrappedComponent)})`;

    render() {
      setWebpackPublicPath(this.props.cssBaseUrl);
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithCustomStyles, WrappedComponent);

  return WithCustomStyles;
};
