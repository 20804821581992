import InvalidAppStatePage from '../components/invalid-app-state-page';
import { withStyles } from './with-styles';
import { withWebpackPublicPath } from './with-webpack-public-path';
import { withLayoutUpdate } from './with-layout-update';
import { withInvalidAppState } from './with-invalid-app-state';
import { withSentryErrorBoundary } from './with-sentry-error-boundary';
import { withDirection } from './with-direction';

export function withViewer(AppRoot, stylesConfig, widgetName) {
  const FallbackPage = withDirection(withStyles(InvalidAppStatePage, stylesConfig));
  const AppPage = withLayoutUpdate(withWebpackPublicPath(withDirection(withStyles(AppRoot, stylesConfig))));
  return {
    component: withSentryErrorBoundary(withInvalidAppState(AppPage, FallbackPage), widgetName, FallbackPage),
  };
}
