import { get } from 'lodash';
import { SENTRY_VIEWER_SCRIPT_DSN } from '../constants/sentry-viewer-script-dsn';
import getEnvironment from './get-environment';

const isProduction = process.env.NODE_ENV === 'production';

export const getEnvironmentTag = ({ origin, isSSR, isSeo }) =>
  `${origin}-${isSSR ? 'ssr' : 'csr'}${isSeo ? '-seo' : ''}${!isProduction ? '-dev' : ''}`;

export function createMonitoring({ appParams, wixCodeApi, platformAPIs, type, origin }) {
  const { fedOpsLoggerFactory, monitoring } = platformAPIs;
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);

  const sentry = monitoring.createMonitor(SENTRY_VIEWER_SCRIPT_DSN, (data) => {
    let isSeo = false;
    try {
      isSeo = wixCodeApi.seo.isInSEO();
    } catch {}
    data.environment = getEnvironmentTag({ origin, isSSR, isSeo });
    return data;
  });

  const fedopsLogger = type
    ? fedOpsLoggerFactory.getLoggerForWidget({
        appId: appParams.appDefinitionId,
        widgetId: type,
      })
    : undefined;

  const captureToSentry = (e) => {
    sentry.captureException(e);
    const errorId = sentry.lastEventId();
    if (isDebug || !isProduction) {
      console.log('error captured to Sentry:', errorId);
    }
    return errorId;
  };

  return {
    sentry,
    captureToSentry,
    fedopsLogger,
  };
}

export const normalizeError = (error) => {
  if (get(error, 'constructor.name') === 'Response' && error.stack) {
    return error.stack;
  }
  return error;
};
